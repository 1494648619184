import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import SecondaryHeader from "../../components/SecondaryHeader"
import SupportCenterTopicWrapper from "../../components/SupportCenterLayout"

const TheMainDashboardPage = ({ data }) => {
  return (
    <Layout>
      <Seo title={`Easy to use cloud platform`} />
      <SecondaryHeader title={<>Support Center</>} />

      <div id="support-center-page">
        <section className="support-center">
          <div style={{ paddingBottom: "50px" }}></div>

          <SupportCenterTopicWrapper>
            <div className="support-center__topic-right">
              <h3 className="mb-4">Understanding the Main Dashboard</h3>

              <p>
                Your AlignerBase command center awaits! Get a bird's eye view of
                all your AlignerBase activities in one convenient location.
              </p>

              <div className="mb-4" />

              <h4 id="Main Dashboard">Main Dashboard</h4>

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/4.jpg" />

              <p>
                Each time you sign in, you will be greeted with the main
                dashboard. This is where you can get an overview of all your
                AlignerBase activities.
              </p>

              <div className="mb-4" />

              <h5 id="Account Overview">
                Section: <strong>Account Overview</strong>
              </h5>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/4a.jpg" />

              <div className="mb-4" />

              <p>
                The first section of the dashboard provides a statistical
                overview of your registered patients, cases and refinements. As
                you progress, these numbers will automatically reflect latest
                statistics.
              </p>

              <div className="mb-4" />

              <h5 id="Cases by Treatment Stage">
                Section: <strong>Cases by Treatment Stage</strong>
              </h5>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/4b.jpg" />

              <div className="mb-4" />

              <p>
                All of your cases are divided into unique stages. At each stage
                of the process, you will receive notification emails as the case
                progresses, by default. This setting can be disabled by the user
                through the Profile section.
              </p>

              <ul class="list-disc pl-4">
                <li>
                  In Draft
                  <ul class="list-circle pl-4">
                    <li>These are cases that you are currently drafting.</li>
                  </ul>
                </li>

                <li>
                  Under Review
                  <ul class="list-circle pl-4">
                    <li>
                      Once a draft is submitted, it moves to the review stage.
                    </li>
                  </ul>
                </li>
                <li>
                  Design in Process
                  <ul class="list-circle pl-4">
                    <li>After reviewal, the design process starts.</li>
                  </ul>
                </li>
                <li>
                  Design in QC
                  <ul class="list-circle pl-4">
                    <li>
                      Once design is complete, it passes through a detailed
                      Quality Check performed by a quantified Orthodontist.
                    </li>
                  </ul>
                </li>
                <li>
                  Approval Required
                  <ul class="list-circle pl-4">
                    <li>
                      Once a design passes Quality Control, the design then
                      moves to Approval Required where your input is awaited.
                    </li>
                  </ul>
                </li>
                <li>
                  Completed Cases
                  <ul class="list-circle pl-4">
                    <li>
                      Once a draft is completed, it is added to the Completed
                      Cases total.
                    </li>
                  </ul>
                </li>
                <li>
                  Cancelled Cases
                  <ul class="list-circle pl-4">
                    <li>
                      Cases that have been cancelled at any stage are added to
                      Cancelled cases total.
                    </li>
                  </ul>
                </li>
              </ul>

              <div className="mb-4" />

              <h5 id="Financial Metrics">
                Section: <strong>Financial Metrics</strong>
              </h5>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/4c.jpg" />

              <div className="mb-4" />

              <p>
                This section lists down all financial aspects of your account on
                AlignerBase.
              </p>

              <ul class="list-disc pl-4">
                <li>
                  Payment Plan
                  <ul class="list-circle pl-4">
                    <li>Your current Payment Plan is displayed here.</li>
                  </ul>
                </li>
                <li>
                  Payments Made (Total)
                  <ul class="list-circle pl-4">
                    <li>Payments made by you are listed here.</li>
                  </ul>
                </li>
                <li>
                  Payments Made (This Month)
                  <ul class="list-circle pl-4">
                    <li>
                      Payments that are made only in this month are listed here.
                    </li>
                  </ul>
                </li>
                <li>
                  Payments Pending (Total)
                  <ul class="list-circle pl-4">
                    <li>
                      Payments that are made only in this month are listed here.
                    </li>
                  </ul>
                </li>
                <li>
                  Payments Pending (This Month)
                  <ul class="list-circle pl-4">
                    <li>Payments pending for this month are listed here.</li>
                  </ul>
                </li>
                <li>
                  Credit Amount
                  <ul class="list-circle pl-4">
                    <li>Total credit amount is listed here.</li>
                  </ul>
                </li>
              </ul>

              <div className="mb-4" />

              <h5 id="Alerts">
                Section: <strong>Alerts</strong>
              </h5>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/4d.jpg" />

              <div className="mb-4" />

              <p>
                Here you will find all alerts related to your account sorted
                under individual titles.
              </p>

              <div className="mb-4" />

              <h5 id="Messages">
                Section: <strong>Messages</strong>
              </h5>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/4e.jpg" />

              <div className="mb-4" />

              <p>
                Section: Messages If you want to correspond with our team,
                messages tab will display all open threads as well as allow you
                to create new ones.
              </p>
            </div>
          </SupportCenterTopicWrapper>
        </section>
      </div>
    </Layout>
  )
}

export default TheMainDashboardPage
